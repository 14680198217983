import { CSSProperties } from "react";
import styled from "styled-components";

const StyledImageSection = styled.section<{ image: string }>`
  display: flex;
  background-clip: padding-box;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  // padding: 1vw;
`;

const ImageSection = ({
  image,
  children,
  style,
}: {
  image: string;
  children: JSX.Element[] | JSX.Element;
  style?: CSSProperties;
}) => {
  return (
    <StyledImageSection image={image} style={style}>
      {children}
    </StyledImageSection>
  );
};

export default ImageSection;
