import axios from "axios";
// import process from "process"
import { Question, QuestionStatus, Stack, User } from "./types";

export const API = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:1337"
      : "https://api.pohtia.com",
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
  validateStatus: (_status) => true,
});

export const signUpUser = (
  email: string,
  password: string,
  recaptchaToken: string
): Promise<{ status: number; data: { errors?: any } }> => {
  return API.post("/api/sign-up", {
    email,
    password,
    recaptcha_token: recaptchaToken,
  });
};

export const oauthSignUp = (
  token: string
): Promise<{
  status: number;
  data: { user_id: number; email_confirmed: boolean };
}> => {
  return API.post("/api/sign-up/token", {
    token,
  });
};

export const loginUser = (
  email: string,
  password: string
): Promise<{
  status: number;
  data: { user_id: number; email_confirmed: boolean };
}> => {
  return API.post("/api/login", {
    email,
    password,
  });
};

export const oauthlLoginUser = (
  token: string
): Promise<{
  status: number;
  data: { user_id: number; email_confirmed: boolean };
}> => {
  return API.post("/api/login/token", {
    token,
  });
};

export const logoutUser = (): Promise<{ status: number }> => {
  return API.delete("/api/logout");
};

export const verifyEmail = (
  emailToken: string
): Promise<{ status: number }> => {
  return API.get(`/api/verify/email/${emailToken}`);
};

export const resetPassword = (email: string): Promise<{ status: number }> => {
  return API.post(`/api/users/reset-password`, { email });
};

export const setNewPassword = (
  password: string,
  token: string
): Promise<{ status: number }> => {
  return API.post("/api/users/new-password", { password, token });
};

export const getMe = (): Promise<{
  status: number;
  data: User;
}> => {
  return API.get("/api/users/me");
};

export const getAllStacks = (): Promise<{ data: Stack[]; status: number }> => {
  return API.get("/api/stacks");
};

export const getStack = (
  stackId: string
): Promise<{ data: Stack; status: number }> => {
  return API.get(`/api/stacks/${stackId}`);
};

export const createStack = (
  stackName: string
): Promise<{ status: number; data: Stack }> => {
  return API.post("/api/stacks", {
    name: stackName,
  });
};

export const updateStack = (
  stackId: string,
  stackName: string
): Promise<{ status: number; data: Stack }> => {
  return API.put(`api/stacks/${stackId}`, {
    name: stackName,
  });
};

export const deleteStack = (
  stackId: string
): Promise<{ status: number; data: Stack }> => {
  return API.delete(`api/stacks/${stackId}`);
};

export const getAllQuestions = (
  stackId: string,
  params?: { date?: string; searchTerms?: string[] }
): Promise<{ data: Question[] }> => {
  // const filter = date ? `?date=${date}` : "";
  return API.get(`/api/stacks/${stackId}/questions`, {
    params: {
      date: params && params?.date,
      search_terms: params && params?.searchTerms,
    },
  });
};

export const createQuestion = (
  stackId: string,
  question: string,
  answer: string
): Promise<{ status: number; data: Question }> => {
  return API.post(`api/stacks/${stackId}/questions`, {
    question,
    answer,
  });
};

export const getQuestion = (
  stackId: string,
  questionId: string
): Promise<{ status: number; data: Question }> =>
  API.get(`/api/stacks/${stackId}/questions/${questionId}`);

export const searchQuestions = (
  stackId: string,
  terms: string[]
): Promise<{ status: number; data: Question[] }> =>
  API.get(`/api/stacks/${stackId}/questions/search`, { params: { terms } });

export const updateQuestion = (
  questionId: string,
  stackId: string,
  question: string,
  answer: string
): Promise<{ status: number; data: Question }> =>
  API.put(`/api/stacks/${stackId}/questions/${questionId}`, {
    question,
    answer,
  });

export const updateQuestionOnAnswer = (
  questionId: string,
  stackId: string,
  nextAppearanceDate: string,
  questionStatus: QuestionStatus
): Promise<{
  questionId: string;
  stackId: string;
  nextAppearanceDate: string;
}> =>
  API.put(`/api/stacks/${stackId}/questions/${questionId}`, {
    next_appearance_date: nextAppearanceDate,
    last_status: questionStatus,
    last_answered: new Date().toISOString(),
    count_question: true,
  });

export const deleteQuestion = (
  stackId: string,
  questionId: string
): Promise<{ status: number; data: Question }> =>
  API.delete(`/api/stacks/${stackId}/questions/${questionId}`);

export const upgradeAccount = (
  userId: string
): Promise<{ status: number; data: { url: string } }> =>
  API.post(`api/users/${userId}/upgrade-account`);

export const deleteAccount = (userId: string): Promise<{ status: number }> =>
  API.delete(`api/users/${userId}`);

export const createImage = (
  userId: string,
  imageBinary: string
): Promise<{ status: number; data: { filename: string } }> =>
  API.post(`api/images/${userId}`, { image: imageBinary });

export const createTmpImage = (
  imageBinary: string
): Promise<{ status: number; data: { filename: string } }> =>
  API.post(`api/images/tmp`, { image: imageBinary });

export const nextQuestion = (stackId: string): Promise<{status: number, data: Question}> =>
  API.get(`api/stacks/${stackId}/questions/next-question`);

export const reportAnswer = (stackId: string, questionId: string, result: "easy" | "moderate" | "difficult"): Promise<{status: number, data: Question}> =>
  API.post(`api/stacks/${stackId}/questions/${questionId}/report-answer`, {result: result});

export const resetTimers = (stackId: string): Promise<{status: number, data: {}}> =>
  API.post(`api/stacks/${stackId}/reset-timers`, {});

export const getSocketToken = (): Promise<{status: number, data: {token: string}}> =>
  API.get("api/session/socket-token");