import {
  CSSProperties,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
  useState,
} from "react";
import LoadingSpinner from "../LoadingSpinner";
import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPaste?: (
    e: ClipboardEvent<HTMLTextAreaElement>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  placeholder?: string;
  className?: string;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
}

interface StyleProps {
  theme: Theme;
  disabled?: boolean;
}

const Overlay = styled.div<StyleProps>`
  position: relative;
  top: 30%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StyledTextArea = styled.textarea<StyleProps>`
  border: none;
  background-color: ${(props) => props.theme.colors.muted};
  color: white;
  resize: none;
  font-weight: ${(props) => props.theme.type.regular};
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  padding: ${(props) => props.theme.spacing.paragraphSpacing.normal};
  overflow-y: auto;
  height: inherit;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.pale};
    border: 4px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(props) => props.theme.colors.pale};
  }

  &::-webkit-resizer {
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: ${(props) => props.theme.colors.pale};
    border: none;
  }

  &::-webkit-scrollbar-corner:hover {
    background-color: ${(props) => props.theme.colors.pale};
  }
`;

const TextInput = ({
  value,
  onChange,
  style,
  placeholder,
  onPaste,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const { theme } = useTheme();

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const indent = "  ";
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;

      e.currentTarget.value =
        value.substring(0, start) + indent + value.substring(end, value.length);

      e.currentTarget.setSelectionRange(
        start + indent.length,
        start + indent.length
      );

      onChange({ target: e.currentTarget } as ChangeEvent<HTMLTextAreaElement>);
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
    onPaste && onPaste(e, setLoading);
  };

  return (
    <>
      {loading && (
        <Overlay theme={theme}>
          <LoadingSpinner />
        </Overlay>
      )}
      <StyledTextArea
        value={value}
        theme={theme}
        style={style}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder}
        disabled={loading}
      />
    </>
  );
};

export default TextInput;
