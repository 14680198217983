import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import styles from "./Markdown.module.scss";
import { CSSProperties } from "react";

const LANGUAGE_ALIASES: { [k in string]: string } = {
  js: "javascript",
  py: "python",
};

const Markdown = ({
  value,
  className,
  style,
}: {
  value: string;
  className?: string;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <div className={className} style={style}>
      <ReactMarkdown
        components={{
          code: ({ node, inline, className, ...props }) => {
            if (inline) {
              return (
                <code className={styles.inlineCode}>
                  {props.children.join("").trim()}
                </code>
              );
            }
            const hasLang = /language-(\w+)/.exec(className || "");

            let language = "plaintext";

            if (hasLang) {
              const lang = hasLang[1];
              const alias = LANGUAGE_ALIASES[lang];
              language = alias ? alias : lang;
            }

            return (
              <SyntaxHighlighter
                customStyle={{
                  background: "#222",
                  padding: "1em",
                }}
                language={language}
                lineNumberStyle={{
                  marginRight: "1em",
                }}
                style={monokaiSublime}
              >
                {props.children.join("").trim()}
              </SyntaxHighlighter>
            );
          },
          p: ({ node, className, ...props }) => (
            <p className={styles.markdownParagraph}>{props.children}</p>
          ),
          img: ({ node, className, ...props }) => {
            return (
              <img className={styles.markdownImg} src={props.src} alt="" />
            );
          },
        }}
        children={value}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex, rehypeRaw]}
      />
    </div>
  );
};

export default Markdown;
