import { CSSProperties } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
`;

const Row = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: CSSProperties;
}) => <StyledDiv style={style}>{children}</StyledDiv>;

export default Row;
