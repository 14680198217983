import styled from "styled-components";
import { Theme, useTheme } from "../contexts/ThemeContext";

type BtnStyle = "dark" | "light";

interface Props {
  text: string;
  onClick: () => void;
  btnStyle?: BtnStyle;
}

const ButtonWrapper = styled.span<{ theme: Theme }>`
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Spacing between the circle and the text */
  cursor: pointer;
  position: relative;

  &:hover::after {
    transform: scaleX(1);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0px;
    width: calc(100% - 1px);
    height: 2px;
    background-color: ${(props) => props.theme.colors.dark};
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease;
  }
`;

const ButtonText = styled.span<{ theme: Theme }>`
  font-size: ${(props) => props.theme.fontSize.body};
  font-weight: ${(props) => props.theme.type.regular};
  color: ${(props) => props.theme.colors.dark};
`;

const TextButton = ({ text, onClick, btnStyle = "dark" }: Props) => {
  const { theme } = useTheme();

  return (
    <ButtonWrapper theme={theme} onClick={onClick}>
      <ButtonText theme={theme}>{text}</ButtonText>
    </ButtonWrapper>
  );
};

export default TextButton;
