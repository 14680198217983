import { Col, Row } from "react-flexbox-grid";
import styles from "./Home.module.scss";
import Spacer from "../components/Spacer";
import Markdown from "../components/Markdown";
import { useState } from "react";
import StandoutCard from "../components/StandoutCard";
import TextInput from "../components/TextInput";
import Column from "../components/layout/Column";
import Paragraph from "../components/Paragraph";
import Heading from "../components/Heading";
import Callout from "../components/Callout/Callout";
import styled from "styled-components";
import { Button } from "../components";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../contexts/ThemeContext";
import { Columns, ImageSection } from "../components/layout";
import SubHeading from "../components/SubHeading";
import { createImage, createTmpImage } from "../api";

const latex = `$$
S(\\omega)=\\frac{\\alpha g^2}{\\omega^5} \\,
e ^{[-0.74\\bigl\\{\\frac{\\omega U_\\omega 19.5}{g}\\bigr\\}^{-4}]}
$$`;

const code = `
\`\`\`c
#include <stdio.h>
int main() {
  printf("Hello, World!");
  return 0;
}
\`\`\`
`;

const MathExample = () => {
  const content = (
    <Columns gap="1em" style={{ height: "100%" }}>
      <Column style={{ height: "100%" }}>
        <StandoutCard>
          <div>
            <Paragraph>$$</Paragraph>
            <Paragraph>{`S(\\omega)=\\frac{\\alParagraphha g^2}{\\omega^5} \\,`}</Paragraph>
            <Paragraph>
              {`// e ^{[-0.74\\bigl\\{\\frac{\\omega U_\\omega 19.5}{g}\\bigr\\}^{-4}]}`}
            </Paragraph>
            <Paragraph>$$</Paragraph>
          </div>
        </StandoutCard>
      </Column>

      <Column style={{ height: "100%" }}>
        <StandoutCard xs={10} md={6} className={styles.latexExample}>
          <Markdown value={latex} />
        </StandoutCard>
      </Column>
    </Columns>
  );

  return content;
};

const CodeExample = () => {
  const content = (
    <Row around="xs">
      <StandoutCard xs={10} md={6} className={styles.codeMarkdownExample}>
        <div>
          {code.split("\n").map((l, idx) => (
            <Paragraph className={[4, 5].includes(idx) ? styles.indent : ""}>
              {l}
            </Paragraph>
          ))}
        </div>
      </StandoutCard>

      <StandoutCard xs={10} md={6} className={styles.codeExample}>
        <Markdown value={code} />
      </StandoutCard>
    </Row>
  );

  return content;
};

const ImageExample = () => {
  const content = (
    <Row around="xs">
      <StandoutCard xs={10} md={6} className={styles.imageMarkdownExample}>
        <div>
          <Paragraph>{`![](https://pohtia.com/pohtia_logo.png)`}</Paragraph>
        </div>
      </StandoutCard>
      <StandoutCard xs={10} md={6} className={styles.imageExample}>
        <Markdown value={"![](https://i.imgur.com/3NS5lLI.png)"} />
      </StandoutCard>
    </Row>
  );

  return content;
};

const PaddedSection = styled.section`
  padding: 3vh 5vw;
`;

const TryItExample = () => {
  const [raw, setRaw] = useState<string>(
    `## Change me!

---

### Math example

$$
f(x) = \\frac{1}{2\\pi i} \\oint_{\\gamma} \\frac{e^z}{z^2 + 1} \\, dz
$$

---

### Code example

\`\`\`py
from math import sqrt

def hypotenuse(a, b):
  c = sqrt(a**2 + b**2)
  return c
\`\`\`
---

### Paste your image below

Paste here!
`
  );

  const { theme } = useTheme();

  const StyledContainer = styled.div`
    display: inline-block;
    padding: ${theme.spacing.indentation};
    color: white;
    background-color: ${theme.colors.secondaryColor};
  `;

  const onPaste = async (
    event: React.ClipboardEvent<HTMLTextAreaElement>,
    setLoading: (val: boolean) => void
  ) => {
    const items = event.clipboardData?.items;
    if (!items) return;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        if (!blob) return;

        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64String = event.target?.result as string;
          if (base64String) {
            setLoading(true);

            const { data } = await createTmpImage(base64String);
            const domain =
              process.env.NODE_ENV === "development"
                ? "http://localhost:1337"
                : "https://api.pohtia.com";

            const imageMarkdown = `![Alt text](${domain}/api/images/tmp/${data.filename})`;
            setRaw((prevMarkdown) => prevMarkdown + imageMarkdown);
            setLoading(false);
          }
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  return (
    <PaddedSection>
      <StyledContainer>
        <Heading text="TRY IT" type="l" />
        <SubHeading text="edit the example below" />
      </StyledContainer>
      <Spacer size="0.75em" />
      <Columns gap={theme.spacing.indentation}>
        <Column>
          <TextInput
            value={raw}
            onChange={(e) => setRaw(e.target.value)}
            style={{ height: "100%" }}
            onPaste={onPaste}
          />
        </Column>
        <Column>
          <StandoutCard border>
            <Markdown
              value={raw}
              style={{
                backgroundColor: "white",
                padding: theme.spacing.indentation,
              }}
            />
          </StandoutCard>
        </Column>
      </Columns>
    </PaddedSection>
  );
};

const HeroSection = (): JSX.Element => {
  const navigate = useNavigate();

  const { theme } = useTheme();

  return (
    <ImageSection image="hero_image.png" style={{ padding: "5vw" }}>
      <StandoutCard style={{ maxWidth: "40%" }}>
        <Column>
          <Heading
            text="Flashcards with first class code, math, and Markdown support"
            type="l"
          />
          <Spacer size={theme.spacing.paragraphSpacing.normal} />
          <Paragraph>
            <Callout text="pohtia" /> is the flashcard app and study tool for{" "}
            <Callout text="STEM" />.
          </Paragraph>
          <Paragraph>
            Elevate your learning experience and knowledge retention with our
            standout features, including seamless support for{" "}
            <Callout text="code snippets" />, <Callout text="LaTeX" />,{" "}
            <Callout text="image hosting" /> and <Callout text="Markdown" />.
          </Paragraph>
          <Spacer size="1em" />
          <Button
            text="Sign up for free"
            onClick={() => navigate("/sign-up")}
            padding="m"
          />
        </Column>
      </StandoutCard>
    </ImageSection>
  );
};

const HowItWorksSection = (): JSX.Element => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <ImageSection
      image="ponder.png"
      style={{ padding: "5vw", flexDirection: "column" }}
    >
      <StandoutCard style={{ maxWidth: "40%" }}>
        <Heading text="How it works" type="l" />
        <Spacer size={theme.spacing.paragraphSpacing.normal} />
        <Columns>
          <Column>
            <Callout text="write." />
          </Column>
          <Column>
            <Callout text="study." />
          </Column>
          <Column>
            <Callout text="remember." />
          </Column>
        </Columns>
      </StandoutCard>

      <Spacer size={theme.spacing.paragraphSpacing.normal} />

      <StandoutCard style={{ maxWidth: "40%" }}>
        <Heading text="Turn your notes into flashcards" />
        <Paragraph>
          Use the web interface to write flashcards with <Callout text="code" />
          , <Callout text="LaTeX" />, <Callout text="images" />, and{" "}
          <Callout text="markdown" /> quickly and easily.
        </Paragraph>
        <Spacer
          size={theme.spacing.paragraphSpacing.large}
          withLine
          width="15%"
          justify="left"
        />

        <Heading text="Study anytime, anywhere" />
        <Paragraph>
          Gamify and test your knowledge with <Callout text="pohtia" />{" "}
          self-testing tools.
        </Paragraph>
        <Spacer
          size={theme.spacing.paragraphSpacing.large}
          withLine
          width="15%"
          justify="left"
        />

        <Heading text="Long term retention" />
        <Paragraph>
          Consistent note taking and self-study is the simplest way to get
          complex information into your long term memory.
        </Paragraph>
        <Spacer size={theme.spacing.paragraphSpacing.large} />
        <Column>
          <Button
            text="Sign up for free"
            onClick={() => navigate("/sign-up")}
            padding="m"
          />
        </Column>
      </StandoutCard>
    </ImageSection>
  );
};

const DetailsSection = (): JSX.Element => {
  return (
    <PaddedSection>
      <StandoutCard>
        <Heading text="Seamless math, code, and Markdown support" />
        <Spacer size="0.5em" />
        <Paragraph style={{ maxWidth: "50%" }}>
          Dive into the intricacies of math and coding with ease.{" "}
          <Callout text="pohtia" /> provides robust support for complex
          equations, formulas, and code snippets. Create, edit, and{" "}
          <Callout text="visualize mathematical expressions" /> and{" "}
          <Callout
            text="code
          blocks using Markdown"
          />
          .
        </Paragraph>
      </StandoutCard>

      <Spacer size="1em" />
      <MathExample />

      <CodeExample />

      <Row className={styles.boxShadow}>
        <StandoutCard
          xs={7}
          sm={6}
          style={{ flexDirection: "column", boxShadow: "none" }}
          className={styles.card}
        >
          <Heading text="Automatic image hosting" />
          <Spacer size="0.5em" />
          <Paragraph>
            <Callout text="Copy-pasted images are automatically hosted" /> and
            added to your flash cards. <Callout text="pohtia" /> detects images
            being added to your flash cards and renders them automatically.
          </Paragraph>
        </StandoutCard>
        <Col xs={5} sm={6} className={styles.imageSectionImage}></Col>
      </Row>
      <ImageExample />
    </PaddedSection>
  );
};

const Home = () => {
  return (
    <>
      <HeroSection />
      <TryItExample />
      <HowItWorksSection />
      {/* <DetailsSection /> */}
    </>
  );
};

export default Home;
