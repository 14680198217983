import React from "react";
import ReactModal, { Props } from "react-modal";
import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";

ReactModal.setAppElement("#root");

const StyledContainer = styled(ReactModal)`
  height: 100%;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledContent = styled.div`
  background: white;
  box-shadow: 18px 18px 2px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2em;
  border-radius: 8px;
`;

const Header = styled.div`
  font-size: 1.5em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 1.5em;
`;

const Modal = ({ children, ...props }: Props) => {
  const { theme } = useTheme();

  return (
    <StyledContainer
      overlayElement={(_props, contentEl) => (
        <StyledOverlay>{contentEl}</StyledOverlay>
      )}
      contentElement={(_props, children) => (
        <StyledContent theme={theme}>{children}</StyledContent>
      )}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

Modal.Header = ({ children }: { children: React.ReactNode }) => {
  return <Header>{children}</Header>;
};

Modal.Body = ({ children }: { children: React.ReactNode }) => {
  return <Body>{children}</Body>;
};

Modal.Footer = ({ children }: { children: React.ReactNode }) => {
  return <Footer>{children}</Footer>;
};

export default Modal;
