import styled from "styled-components";
import { useTheme, Theme } from "../contexts/ThemeContext";

const StyledSubHeading = styled.div<{ theme: Theme }>`
  font-variant: small-caps;
  font-weight: ${(props) => props.theme.type.bold};
  font-size: ${(props) => props.theme.fontSize.subHeading};
`;

const SubHeading = ({ text }: { text: string }) => {
  const { theme } = useTheme();

  return <StyledSubHeading theme={theme}>{text}</StyledSubHeading>;
};

export default SubHeading;
