import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";

type SpaceType = "around" | "top" | "bottom";

interface Props {
  withLine?: boolean;
  lineStyle?: "solid" | "dashed";
  size?: string;
  width?: string;
  justify?: "left" | "center" | "right";
  space?: SpaceType;
}

const calcMargin = (space: SpaceType, size: string) => {
  switch (space) {
    case "top":
      return { marginTop: size };
    case "bottom":
      return { marginBottom: size };
    case "around":
    default:
      return { margin: `${size} 0px` };
  }
};

const StyledSpacer = styled.div<Pick<Props, "justify" | "width">>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) =>
    justify === "left"
      ? "flex-start"
      : justify === "right"
      ? "flex-end"
      : "center"};
  width: ${({ width }) => width || "100%"};
`;

const StyledLine = styled.div<{
  withLine: boolean;
  lineStyle: string;
  theme: any;
}>`
  width: 100%;
  height: 1px;
  ${({ withLine, lineStyle, theme }) =>
    withLine &&
    (lineStyle === "dashed"
      ? `
      border: none;
      background: repeating-linear-gradient(
        to right,
        ${theme.colors.dark} 0,
        ${theme.colors.dark} 10px,
        transparent 10px,
        transparent 20px
      );
      `
      : `
      border-top: 1px solid ${theme.colors.dark};
      `)};
`;

const Spacer = ({
  withLine = false,
  lineStyle = "solid",
  size = "1rem",
  width = "100%",
  justify = "center",
  space = "around",
}: Props): JSX.Element => {
  const { theme } = useTheme();
  const margin = calcMargin(space, size);

  return (
    <StyledSpacer justify={justify} width={width} style={margin}>
      {withLine && (
        <StyledLine withLine={withLine} lineStyle={lineStyle} theme={theme} />
      )}
    </StyledSpacer>
  );
};

export default Spacer;
