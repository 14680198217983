import styled from "styled-components";
import Heading from "../components/Heading";
import { useTheme } from "../contexts/ThemeContext";
import Spacer from "../components/Spacer";
import { Row } from "../components/layout";
import CommandButton from "../components/CommandButton";
import { To, useNavigate } from "react-router-dom";
import { Action, Navigation, PageProps } from "./types";

interface Props extends PageProps {
  title: string;
}

const NavigateActions = ({ navigation }: { navigation: Navigation[] }) => {
  const navigate = useNavigate();
  return (
    <Row style={{ gap: "5em" }}>
      {navigation.map((n) => (
        <CommandButton
          command={n.command || "back"}
          text={n.title}
          onClick={() => navigate(n.to as To)}
          color="dark"
        />
      ))}
    </Row>
  );
};

const Actions = ({
  actions,
  justifyActions,
}: {
  actions: Action[];
  justifyActions?: string;
}) => {
  return (
    <Row style={{ gap: "5em", justifyContent: justifyActions || "flex-start" }}>
      {actions.map((a) => (
        <CommandButton
          command={a.command || "add"}
          text={a.title}
          onClick={() => a.onClick()}
          color={a.color}
        />
      ))}
    </Row>
  );
};

const StyledContainer = styled.div`
  /* Base styles for mobile-first */
  padding: 3vh 3vw;

  /* Small devices (portrait tablets and large phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 3vh 4vw;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media (min-width: 768px) {
    padding: 4vh 5vw;
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 3vh 3vw;
  }

  /* Extra Large devices (large desktops, 1200px and up) */
  @media (min-width: 1920px) {
    padding: 5vh 20vw;
  }
`;

const TitledPage = ({
  title,
  children,
  actions,
  navigation,
  justifyActions,
}: Props) => {
  const { theme } = useTheme();
  return (
    <StyledContainer>
      {navigation && navigation.length > 0 ? (
        <>
          <NavigateActions navigation={navigation} />
          <Spacer size={theme.spacing.paragraphSpacing.large} />
        </>
      ) : null}

      <Heading text={title} type="xl" style={{}} />

      {actions && actions?.length > 0 ? (
        <>
          <Spacer size={theme.spacing.paragraphSpacing.normal} />
          <Actions actions={actions} justifyActions={justifyActions} />
          <Spacer size={theme.spacing.paragraphSpacing.large} />
        </>
      ) : (
        <Spacer size={theme.spacing.paragraphSpacing.large} />
      )}
      {children}
    </StyledContainer>
  );
};

export default TitledPage;
