import styles from "./QuickStart.module.scss";
import Spacer from "../components/Spacer";
import { Col, Row } from "react-flexbox-grid";
import StandoutCard from "../components/StandoutCard";
import GridWrapper from "../components/GridWrapper";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";
import Callout from "../components/Callout/Callout";
import { Column, Columns, ImageSection } from "../components/layout";

const markdown = `Inline LaTeX example: $\\frac{1}{2}$

---
Block LaTex example:
$$
\\frac{\\partial u}{\\partial t} = h^2 \\left( \\frac{\\partial^2 u}{\\partial x^2} + \\frac{\\partial^2 u}{\\partial y^2} + \\frac{\\partial^2 u}{\\partial z^2} \\right)
$$
---
Inline code example: \`a + b = c\`

---
Code block example:
\`\`\`py
def my_func():
  a**2 + b**2 = c**2
\`\`\``;

const indentedStyle = {
  marginLeft: "10%",
  display: "inline-block",
};

const QuickStart = () => {
  return (
    <ImageSection
      image="clutter.png"
      style={{ flexDirection: "column", padding: "3vw 5vh" }}
    >
      <StandoutCard style={{ maxWidth: "33%" }}>
        <Heading text="Quick start" type="l" />
        <Spacer size="1em" />
        <Paragraph>
          Welcome to <Callout text="pohtia" />.
        </Paragraph>
        <Paragraph>
          This guide will help you get started with using the app's two main
          concepts: cards and stacks.
        </Paragraph>
      </StandoutCard>
      <Spacer size="1em" />

      <StandoutCard style={indentedStyle}>
        <Heading text="1. Sign up and log in" />
        <Paragraph>
          The first thing you'll need to do is{" "}
          <Callout text="create an account" />,{" "}
          <Callout text="verify your email" />, and <Callout text="log in" />.
        </Paragraph>
      </StandoutCard>
      <Spacer size="1em" />

      <StandoutCard style={indentedStyle}>
        <Heading text="2. Create a stack" />
        <Paragraph>After logging in, you'll land on the dashboard.</Paragraph>

        <Paragraph>
          You'll have a default stack called <Callout text="My stack" /> but can
          create a new one by clicking on the <Callout text="Add a stack" />{" "}
          button to making your own. Give your stack a name that reflects the
          subject or topic you want to study.
        </Paragraph>

        <Paragraph>
          Save the stack and it will appear in your main stack list.
        </Paragraph>
      </StandoutCard>
      <Spacer size="1em" />

      <StandoutCard style={indentedStyle}>
        <Heading text="3. Add cards to a stack" />
        <Paragraph>
          Click on <Callout text="view" /> to open a stack.
        </Paragraph>
        <Paragraph>
          Inside the stack, click on the <Callout text="Add card" /> button.
          Fill in the question and answer fields for the card - these fields
          support markdown with LaTeX and code blocks.
        </Paragraph>
        <Paragraph>All Markdown syntax is supported.</Paragraph>

        <Columns gap="1em">
          <Column>
            <Paragraph>
              To write inline LaTeX math notation place your markup between two
              dollar signs: <span className={styles.inlineFormat}>$</span>
            </Paragraph>

            <Paragraph>
              To write a LaTeX math notation block place your markup between two
              double dollar signs:{" "}
              <span className={styles.inlineFormat}>$$</span>
            </Paragraph>

            <Paragraph>
              To write inline code place your markup between two backticks:
              <span className={styles.inlineFormat}>`</span>
            </Paragraph>

            <Paragraph>
              To write a code block place your markup between three backticks:{" "}
              <span className={styles.inlineFormat}>```</span>
            </Paragraph>

            <Paragraph>
              You can add as many cards as you want to the stack by repeating
              this process.
            </Paragraph>
          </Column>

          <Column>
            <div className={styles.dummyMarkdown}>
              <Paragraph>Inline LaTeX example: {`$\\frac{1}{2}$`}</Paragraph>
              <Paragraph>---</Paragraph>
              <Paragraph>Block LaTex example:</Paragraph>
              <Paragraph>{`$$`}</Paragraph>
              <Paragraph>{`\\frac{\\partial u}{\\partial t} = h^2 \\left( \\frac{\\partial^2 u}{\\partial x^2} + \\frac{\\partial^2 u}{\\partial y^2} + \\frac{\\partial^2 u}{\\partial z^2} \\right)`}</Paragraph>
              <Paragraph>{`$$`}</Paragraph>
              <Paragraph>---</Paragraph>
              <Paragraph>Inline code example: {`\`a + b = c\``}</Paragraph>
              <Paragraph>---</Paragraph>
              <Paragraph>Code block example:</Paragraph>
              <Paragraph>{"```py"}</Paragraph>
              <Paragraph>{"def my_func():"}</Paragraph>
              <Paragraph className={styles.indent}>
                {"  a**2 + b**2 = c**2"}
              </Paragraph>
              <Paragraph>{"```"}</Paragraph>
            </div>
          </Column>
        </Columns>
      </StandoutCard>
      <Spacer size="1em" />

      <StandoutCard style={indentedStyle}>
        <Row>
          <Heading text="4. Study" />
        </Row>
        <Row>
          <Paragraph>
            Once you've added your cards to your stack you can study them.
          </Paragraph>
          <Paragraph>
            Clicking the <span className={styles.callout}>study</span> button
            from the list view or the stack page will randomly present your
            cards to you one at time.
          </Paragraph>
          <Paragraph>
            You will see the question from your card. Take the time to write
            down the answer, answer out loud or in your head.
          </Paragraph>
          <Paragraph>
            <Callout text="Repeat this as often as you can!" /> The more often
            and more casually you study, the better you long term memory
            retention.
          </Paragraph>
        </Row>
      </StandoutCard>
    </ImageSection>
  );
};

export default QuickStart;
