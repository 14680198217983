import React, { useContext } from "react";

const TYPE_WEIGHTS = {
  bold: 800,
  semiBold: 700,
  regular: 600,
  semiLight: 500,
  light: 400,
};

const COLORS = {
  blue: "#cbd9ea",
  red: "#f25b44",
  navy: "#333c48",
  paleBlue: "#ced8f2",
  charcoal: "#1b1b1e",
  crimson: "#E63946",
  softWhite: "#F1FAEE",
  mint: "#A8DADC",
  steelBlue: "#457B9D",
  gunMetal: "#343A40",
  vibrantGreen: "#2EC4B6", // Added for 'easy'
  amber: "#FFB400", // Added for 'moderate'
  deepPurple: "#5A189A", // Added for 'difficult'
};

const THEME_COLORS = {
  primaryColor: COLORS.crimson,
  secondaryColor: COLORS.crimson,
  warning: COLORS.crimson,
  muted: COLORS.navy,
  pale: COLORS.paleBlue,
  dark: COLORS.charcoal,
  light: "white",
  highlight: COLORS.mint,
  easy: COLORS.vibrantGreen,
  moderate: COLORS.amber,
  difficult: COLORS.deepPurple,
};

export type NamedColor = keyof typeof THEME_COLORS;

const SPACING = {
  lineHeight: {
    compact: "1.2",
    normal: "1.5",
    loose: "2",
  },
  letterSpacing: {
    tight: "-0.05em",
    normal: "0",
    wide: "0.1em",
  },
  paragraphSpacing: {
    small: "0.5em",
    normal: "1em",
    large: "2em",
  },
  indentation: "1em",
};

const FONT_SIZES = {
  small: "12px",
  body: "16px",
  subHeading: "18px",
  heading: "24px",
  display: "32px",
  largeDisplay: "42px",
};

export type Theme = {
  colors: {
    primaryColor: string;
    secondaryColor: string;
    warning: string;
    muted: string;
    pale: string;
    dark: string;
    light: string;
    highlight: string;
    easy: string;
    moderate: string;
    difficult: string;
  };
  type: {
    bold: number;
    semiBold: number;
    regular: number;
    semiLight: number;
    light: number;
  };
  spacing: {
    lineHeight: {
      compact: string;
      normal: string;
      loose: string;
    };
    letterSpacing: {
      tight: string;
      normal: string;
      wide: string;
    };
    paragraphSpacing: {
      small: string;
      normal: string;
      large: string;
    };
    indentation: string;
  };
  fontSize: {
    small: string;
    body: string;
    subHeading: string;
    heading: string;
    display: string;
    largeDisplay: string;
  };
};

type ThemeContextType = {
  theme: Theme;
};

const ThemeContext = React.createContext<ThemeContextType>({
  theme: {
    colors: {
      ...THEME_COLORS,
    },
    type: {
      ...TYPE_WEIGHTS,
    },
    spacing: {
      ...SPACING,
    },
    fontSize: {
      ...FONT_SIZES,
    },
  },
});

export const ThemeProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: {
          colors: {
            ...THEME_COLORS,
          },
          type: {
            ...TYPE_WEIGHTS,
          },
          spacing: {
            ...SPACING,
          },
          fontSize: {
            ...FONT_SIZES,
          },
        },
      }}
      {...props}
    />
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
