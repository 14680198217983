import { CSSProperties } from "react";
import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

type ChildProp = JSX.Element | string;

interface Props {
  children: ChildProp[] | ChildProp;
  style?: CSSProperties;
  className?: string;
}

const StyledParagraph = styled.p<{ theme: Theme }>`
  font-size: ${(props) => props.theme.fontSize.body};
  line-height: ${(props) => props.theme.spacing.lineHeight.normal};
`;

const Paragraph = ({ children, style, className }: Props): JSX.Element => {
  const { theme } = useTheme();

  return (
    <StyledParagraph theme={theme} style={style || {}}>
      {children}
    </StyledParagraph>
  );
};

export default Paragraph;
