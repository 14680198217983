import styled from "styled-components";

interface GridProps {
  columns?: string; // Defines grid-template-columns (e.g., "1fr 2fr" or "repeat(3, 1fr)")
  rows?: string; // Defines grid-template-rows (e.g., "1fr 100px")
  gap?: string; // Defines the gap between grid items (e.g., "10px" or "1rem")
  children: JSX.Element[] | JSX.Element;
  className?: string; // Additional CSS classes
}

const StyledGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "1fr"};
  grid-template-rows: ${({ rows }) => rows || "auto"};
  gap: ${({ gap }) => gap || "1rem"};
  width: 100%;
`;

const Grid = ({ columns, rows, gap, children, className }: GridProps) => {
  return (
    <StyledGrid columns={columns} rows={rows} gap={gap} className={className}>
      {children}
    </StyledGrid>
  );
};

export default Grid;
