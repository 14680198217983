import { useEffect, useRef, useState } from "react";
import { Question, Stack as StackType } from "../types";
import { deleteStack, getAllQuestions, getStack } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import QuestionCard from "../components/QuestionCard";
import Input from "../components/Input";
import { useTheme } from "../contexts/ThemeContext";
import Loading from "../components/Loading";
import { debounce } from "lodash";
import TitledPage from "../layouts/TitledPage";
import { Column, Row } from "../components/layout";
import styled from "styled-components";
import Spacer from "../components/Spacer";
import { Action } from "../layouts/types";
import Heading from "../components/Heading";

const CardList = ({
  stackId,
  stackName,
  searchTerm,
}: {
  stackId: string;
  stackName: string;
  searchTerm: string;
}) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isInitialRender = useRef<boolean>(true);

  // Fetch all questions on initial render
  useEffect(() => {
    const fetchInitialQuestions = async () => {
      setLoading(true);
      try {
        const { data } = await getAllQuestions(stackId);
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialQuestions();
  }, [stackId]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    (async () => {
      setLoading(true);
      if (searchTerm.trim() === "") {
        const { data } = await getAllQuestions(stackId);
        setQuestions(data);
      } else {
        const { data } = await getAllQuestions(stackId, {
          searchTerms: searchTerm.split(" "),
        });
        setQuestions(data);
      }

      setLoading(false);
    })();
  }, [searchTerm, stackId]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : questions.length === 0 ? (
        <EmptyState />
      ) : (
        <Column style={{ gap: "1em" }}>
          {questions.map(({ id, question, answer }) => (
            <QuestionCard
              key={id}
              stackId={stackId || ""}
              questionId={id}
              question={question}
              answer={answer}
              stackName={stackName}
            />
          ))}
        </Column>
      )}
    </>
  );
};

const EmptyState = () => {
  const {
    theme: {
      colors: { primaryColor },
    },
  } = useTheme();

  return (
    <Row style={{ justifyContent: "center", fontStyle: "italic" }}>
      <Heading text="No questions" type="l" />
    </Row>
  );
};

const Stack = () => {
  const [stack, setStack] = useState<StackType>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { stackId } = useParams();
  const navigate = useNavigate();
  const { theme } = useTheme();

  useEffect(() => {
    (async () => {
      if (stackId) {
        setLoading(true);
        const { data: stackData } = await getStack(stackId);
        setStack(stackData);
        setLoading(false);
      }
    })();
  }, [stackId]);

  if (loading || !stack || !stackId) return <Loading />;

  return (
    <TitledPage
      title={stack.name}
      navigation={[
        {
          title: "All stacks",
          command: "back",
          to: "/",
        },
      ]}
      actions={[
        ...(stack.number_of_questions > 0
          ? [
              {
                title: "Study",
                disabled: stack.number_of_questions === 0,
                command: "misc",
                onClick: () => navigate(`/stacks/${stackId}/study`),
                color: "easy",
              } as Action,
            ]
          : []),
        {
          title: "Add question",
          onClick: () => {
            navigate(`/stacks/${stackId}/questions/new`, {
              state: { stackName: stack.name },
            });
          },
          color: "moderate",
        },
        {
          title: "Delete stack",
          command: "delete",
          onClick: () => {
            if (!stack) return;
            // eslint-disable-next-line no-restricted-globals
            const confirmed = confirm(
              "Are you sure you want to delete this stack and all its questions?"
            );

            if (confirmed) {
              deleteStack(String(stack.id));
              navigate("/");
            }
          },
          color: "difficult",
        },
      ]}
    >
      <Row>
        <Input
          placeHolder="Search questions"
          onChange={debounce(setSearchTerm, 300)}
          style={{ border: `2px solid ${theme.colors.dark}` }}
        />
      </Row>
      <Spacer size="1em" />

      <CardList
        stackId={stackId}
        stackName={stack.name}
        searchTerm={searchTerm}
      />
    </TitledPage>
  );
};

export default Stack;
