import Spacer from "../Spacer";
import Heading from "../Heading";
import styled from "styled-components";
import { useTheme } from "../../contexts/ThemeContext";

type Fields = JSX.Element | string | null;

interface Props {
  text: string;
  onSubmit: (formData: any) => void;
  children: JSX.Element | Fields[];
  id?: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const NamedForm = (props: Props) => {
  const {
    theme: { spacing },
  } = useTheme();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onSubmit(e);
  };
  return (
    <StyledForm id={props.id} onSubmit={handleSubmit}>
      <Heading text={props.text} type="l" />
      <Spacer size={spacing.paragraphSpacing.normal} />
      {props.children}
    </StyledForm>
  );
};

NamedForm.Row = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  margin-bottom: 2em;
`;

export default NamedForm;
