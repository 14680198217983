import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";
import { CSSProperties } from "react";

type HeadingType = "xl" | "l" | "m" | "s" | "xs";

interface Props {
  text: string;
  type?: HeadingType;
  style?: CSSProperties;
}

const Heading = ({ text, type = "m", style }: Props): JSX.Element => {
  const { theme } = useTheme();

  const calcWeight = (t: HeadingType, theme: Theme) => {
    switch (t) {
      case "xl":
        return theme.type.regular;
      case "l":
        return theme.type.regular;
      case "m":
        return theme.type.regular;
      case "s":
        return theme.type.bold;
      case "xs":
        return theme.type.bold;
    }
  };

  const calcSize = (t: HeadingType) => {
    switch (t) {
      case "xl":
        return theme.fontSize.largeDisplay;
      case "l":
        return theme.fontSize.display;
      case "m":
        return theme.fontSize.heading;
      case "s":
        return theme.fontSize.subHeading;
      case "xs":
        return theme.fontSize.body;
    }
  };

  const StyledHeading = styled.div<{ type: HeadingType; theme: Theme }>`
    font-size: ${(props) => calcSize(props.type)};
    font-weight: ${(props) => calcWeight(props.type, props.theme)};
  `;

  return (
    <StyledHeading type={type} theme={theme} style={style}>
      {text}
    </StyledHeading>
  );
};

export default Heading;
