import { useState } from "react";
import styles from "./QuestionCard.module.scss";
import Card from "../Card";
import Markdown from "../Markdown";
import { useNavigate } from "react-router-dom";
import { Col } from "react-flexbox-grid";
import StandoutCard from "../StandoutCard";
import Heading from "../Heading";
import { useTheme } from "../../contexts/ThemeContext";
import Spacer from "../Spacer";
import CommandButton from "../CommandButton";
import { Row } from "../layout";

const OpenContent = () => {};

function QuestionCard({
  stackId,
  questionId,
  question,
  answer,
  stackName,
}: {
  stackId: string;
  questionId: number;
  question: string;
  answer: string;
  stackName?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  return (
    <StandoutCard style={{ border: `2px solid ${theme.colors.dark}` }}>
      <Row style={{ justifyContent: "flex-end", gap: "2em" }}>
        <CommandButton
          text={isOpen ? "Collapse" : "Expand"}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          command={isOpen ? "delete" : "add"}
        />
        <CommandButton
          text="Edit"
          onClick={() => {
            navigate(`/stacks/${stackId}/questions/${questionId}`, {
              state: { stackName: stackName },
            });
          }}
          command={"misc"}
        />
      </Row>
      <Markdown
        value={question}
        style={{ padding: theme.spacing.indentation }}
      />

      {isOpen && (
        <>
          <Spacer size="1em" withLine lineStyle="dashed" />
          <Markdown
            value={answer}
            style={{ padding: theme.spacing.indentation }}
          />
          <Spacer size="1em" withLine lineStyle="dashed" />
        </>
      )}
    </StandoutCard>
  );
}

export default QuestionCard;
