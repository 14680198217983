import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.scss";
import LabeledInput from "../components/LabeledInput";
import { Button } from "../components";
import NamedForm from "../components/NamedForm/NamedForm";
import { Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";
import GoogleLogo from "../google-icon-logo-svg-vector.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { oauthlLoginUser } from "../api";
import Spacer from "../components/Spacer";
import { toast } from "react-toastify";
import Callout from "../components/Callout/Callout";
import { ImageSection } from "../components/layout";

interface LoginFormData {
  email: string;
  password: string;
}

const Login = (): JSX.Element => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>("");

  const { login, setIsAuthenticated, setUserId, setIsVerified } = useAuth();
  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    try {
      const success = await login(formData.email, formData.password);
      if (success) {
        navigate("/");
      } else {
        setError("Incorrect email or password.");
      }
    } catch (error) {
      toast("Error with login.", { type: "error" });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      const { status, data } = await oauthlLoginUser(res.access_token);
      if (status === 200) {
        setIsAuthenticated(true);
        setUserId(String(data.user_id));
        setIsVerified(true);

        navigate("/");
      } else {
        setError("Google login failed.");
      }
    },
    onError: (error) => setError(`Google login failed: ${error}`),
  });

  return (
    <ImageSection
      image="clutter.png"
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StandoutCard style={{ minWidth: "33%", maxWidth: "50%" }}>
        <NamedForm text="Login" onSubmit={handleSubmit}>
          <NamedForm.Row>
            <Button
              icon={GoogleLogo}
              type="button"
              text="Login with Google"
              onClick={() => googleLogin()}
            />
          </NamedForm.Row>
          <Spacer withLine size="1em" />

          <NamedForm.Row>
            <LabeledInput
              text="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </NamedForm.Row>

          <NamedForm.Row>
            <LabeledInput
              text="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </NamedForm.Row>
          <NamedForm.Row>
            {error && <Callout text={error} type="error" />}
          </NamedForm.Row>

          <NamedForm.Row>
            <Button type="submit" text="Login" onClick={() => {}} />
          </NamedForm.Row>

          <NamedForm.Row style={{ maxWidth: "50%", alignSelf: "center" }}>
            <Button
              type="submit"
              text="Forgot password"
              onClick={() => {
                navigate("/forgot-password");
              }}
              btnStyle="light"
              padding="s"
            />
          </NamedForm.Row>
        </NamedForm>
      </StandoutCard>
    </ImageSection>
  );
};

export default Login;
