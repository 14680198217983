import { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Heading from "./Heading";
import { Column } from "./layout";
import { useAuth } from "../contexts/AuthContext";
import { Socket } from "phoenix";
import { getSocketToken } from "../api";
import { Theme, useTheme } from "../contexts/ThemeContext";

const LoadingText = () => (
  <span style={{ fontStyle: "italic", fontSize: "14px" }}>Loading...</span>
);

const TickerContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledTicker = styled.div<{ theme: Theme }>`
  z-index: 2; /* Ensure ticker is above XpDiff */
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.colors.easy};
  padding: 0.5em 1em;
  width: 120px;
  border-radius: 10px;
`;

const xpChangeAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    font-weight: 800;
  }
  100% {
    transform: scale(1);
    color: white;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
`;

const AnimatedXp = styled.span<{ isAnimating: boolean }>`
  display: inline-block;
  ${({ isAnimating }) =>
    isAnimating &&
    css`
      animation: ${xpChangeAnimation} 0.5s;
    `}
`;

const XpDiff = styled.span<{ theme: Theme }>`
  position: absolute;
  top: 120%; /* Position below the ticker */
  left: 50%;
  transform: translateX(-50%);
  color: ${(props) => props.theme.colors.dark};
  font-weight: 800;
  z-index: 1; /* Below the ticker */
  animation: ${fadeOutAnimation} 4s ease forwards;
`;

const XpTicker = () => {
  const [xp, setXp] = useState<number>(1);
  const [prevXp, setPrevXp] = useState<number>(1);
  const [xpDiff, setXpDiff] = useState<number | null>(null);
  const [socket, setSocket] = useState<Socket>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const { userId } = useAuth();
  const { theme } = useTheme();

  useEffect(() => {
    const initializeSocket = async () => {
      try {
        const url =
          process.env.NODE_ENV === "development"
            ? "ws://localhost:1337"
            : "wss://api.pohtia.com";
        const { status, data } = await getSocketToken();
        if (status >= 400) return;
        const newSocket = new Socket(`${url}/socket`, {
          params: { token: data.token },
        });
        newSocket.connect();
        setSocket(newSocket);
      } catch (error) {
        console.error(
          "Failed to fetch socket token or initialize socket:",
          error
        );
      }
    };

    initializeSocket();
  }, []);

  useEffect(() => {
    if (!socket || !userId) return;

    const channel = socket.channel(`user_xp:${userId}`, {});

    channel
      .join()
      .receive("ok", (res) => {
        setXp(res.initial_xp);
        setPrevXp(res.initial_xp);
        setLoading(false);
      })
      .receive("error", (err) => console.error(err));

    channel.on("xp_update", (payload) => {
      setXpDiff(payload.xp - xp);
      setXp(payload.xp);
    });

    return () => {
      channel.leave();
    };
  }, [socket, userId, xp]);

  useEffect(() => {
    if (xpDiff !== null) {
      const timer = setTimeout(() => setXpDiff(null), 1000);
      return () => clearTimeout(timer);
    }
  }, [xpDiff]);

  useEffect(() => {
    if (prevXp !== null && xp !== prevXp) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 500);
    }
    setPrevXp(xp);
  }, [xp, prevXp]);

  return (
    <TickerContainer>
      <StyledTicker theme={theme}>
        <Column style={{ flexBasis: "25%" }}>
          <Heading text="XP" type="xs" />
        </Column>
        <Column style={{ flexBasis: "50%", alignItems: "flex-end" }}>
          {loading ? (
            <LoadingText />
          ) : (
            <AnimatedXp isAnimating={isAnimating}>{xp}</AnimatedXp>
          )}
        </Column>
      </StyledTicker>

      {xpDiff !== null && <XpDiff theme={theme}>+{xpDiff}</XpDiff>}
    </TickerContainer>
  );
};

export default XpTicker;
