import { ColProps } from "react-flexbox-grid";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Theme, useTheme } from "../../contexts/ThemeContext";

interface Props extends ColProps {
  children: any;
  border?: boolean;
  noShadow?: boolean;
  padding?: string;
  className?: string;
  styles?: CSSProperties;
}

interface StyleProps {
  theme: Theme;
  border: boolean;
  noShadow: boolean;
}

const StyledCard = styled.div<StyleProps>`
  background-color: white;
  padding: 2em;
  box-shadow: ${(props) =>
    props.noShadow ? "none" : "12px 12px 2px 1px rgba(0, 0, 0, 0.2)"};
  border: ${(props) =>
    props.border ? `2px solid ${props.theme.colors.dark};` : "none;"};
`;

const StandoutCard = ({
  children,
  style,
  border,
  noShadow,
}: Props): JSX.Element => {
  const { theme } = useTheme();

  return (
    <StyledCard
      style={style}
      theme={theme}
      border={!!border}
      noShadow={!!noShadow}
    >
      {children}
    </StyledCard>
  );
};

export default StandoutCard;
