import { useEffect, useState } from "react";
import { Stack } from "../types";
import { createStack, getAllStacks, updateStack } from "../api";
import { useNavigate } from "react-router-dom";
// import Modal from "react-modal";
import Modal from "../components/Modal";
import styles from "./Stacks.module.scss";
import { useAuth } from "../contexts/AuthContext";
import NamedForm from "../components/NamedForm/NamedForm";
import LabeledInput from "../components/LabeledInput";
import Card from "../components/Card";
import PageHeading from "../components/PageHeading";
import { Col, Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import CustomGrid from "../components/CustomGrid";
import StandoutCard from "../components/StandoutCard";
import Spacer from "../components/Spacer";
import LoadingSpinner from "../components/LoadingSpinner";
import { Button } from "../components";
import { Column, Columns, Grid } from "../components/layout";
import TitledPage from "../layouts/TitledPage";
import { useTheme } from "../contexts/ThemeContext";
import Heading from "../components/Heading";
import Paragraph from "../components/Paragraph";

const NameModal = ({
  isOpen,
  onClose,
  onSave,
  stack,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (id: number | undefined, name: string) => void;
  stack?: Stack;
}) => {
  const [name, setName] = useState<string>("");

  useEffect(() => {
    if (stack) {
      setName(stack.name);
    }
  }, [stack]);

  const handleSave = (_e: React.FormEvent<HTMLFormElement>) => {
    onSave(stack?.id, name);
    setName("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Body>
        <NamedForm
          id="new-stack-form"
          text={stack ? "Edit Stack" : "Create a new stack"}
          onSubmit={handleSave}
        >
          <LabeledInput
            text="Stack name"
            name="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </NamedForm>
      </Modal.Body>
      <Modal.Footer>
        <Button text="Cancel" onClick={onClose} noShadow />

        <Button
          text="Confirm"
          type="submit"
          form="new-stack-form"
          onClick={() => {}}
          noShadow
        />
      </Modal.Footer>
    </Modal>
  );
};

const StackCard = ({ stack }: { stack: Stack }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  return (
    <StandoutCard style={{ border: `2px solid ${theme.colors.dark}` }}>
      <Heading text={stack.name} />
      <Paragraph>{`${stack.number_of_questions} question(s)`}</Paragraph>

      <Columns gap="1em" style={{ flex: "0 0 50%" }}>
        <Button
          text="Study"
          disabled={stack.number_of_questions === 0}
          onClick={() =>
            navigate(`/stacks/${stack.id}/study`, {
              state: { stackName: stack.name },
            })
          }
          padding="s"
        />
        <Button
          text="View"
          onClick={() => navigate(`/stacks/${stack.id}`)}
          padding="s"
        />
      </Columns>
    </StandoutCard>
  );
};

const Stacks = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [stacks, setStacks] = useState<Stack[]>([]);
  const [stackIdx, setStackIdx] = useState<number | null>(null);
  const navigate = useNavigate();
  const { logout, isVerified } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await getAllStacks();
        if (status >= 200 && status < 400) {
          setStacks(data);
          setLoading(false);
        } else {
          logout();
        }
      } catch (e) {
        logout();
      }
    })();
  }, [logout]);

  if (!isVerified) {
    return (
      <GridWrapper>
        <StandoutCard
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Row>
            <Col>
              <h1>📣 Check your email</h1>
              <Spacer withLine size="3em" />
              <p>
                You must verify your email before you can access your Pohtia
                account!
              </p>
            </Col>
          </Row>
        </StandoutCard>
      </GridWrapper>
    );
  }

  return (
    <TitledPage
      title="Your Card Stacks"
      actions={[
        {
          title: "Add a stack",
          onClick: () => setShowModal(true),
          color: "easy",
        },
      ]}
    >
      <Grid columns="repeat(3, 1fr)">
        {stacks.length > 0 ? (
          stacks.map((s) => <StackCard stack={s} />)
        ) : (
          <div className={styles.centered}>Add some stacks!</div>
        )}
      </Grid>
      <NameModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        stack={stackIdx !== null ? stacks[stackIdx] : undefined}
        onSave={async (stackId: number | undefined, stackName: string) => {
          if (stackId) {
            const { status, data } = await updateStack(
              String(stackId),
              stackName
            );

            if (status === 200) {
              const newStacks = stacks.map((s) => {
                if (s.id === stackId) {
                  return data;
                }

                return s;
              });
              setStacks(newStacks);
              setShowModal(false);
            }
          } else {
            const { status, data } = await createStack(stackName);

            if (!data.number_of_questions) {
              data.number_of_questions = 0;
            }

            if (status === 200) {
              setStacks([...stacks, data]);
              setShowModal(false);
            }
          }
        }}
      />
    </TitledPage>
  );
};

export default Stacks;
