import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
// import HamburgerMenu from "./HamburgerMenu";
import styled from "styled-components";
import TextButton from "./TextButton";

import XpTicker from "./XpTicker";
import { Theme, useTheme } from "../contexts/ThemeContext";
import { Row } from "./layout";

const NavBarRight = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  return (
    <Row
      style={{
        gap: "3vw",
        justifyContent: isAuthenticated ? "center" : "flex-end",
        flex: "0 1 33%",
      }}
    >
      {isAuthenticated && (
        <TextButton text="stacks." onClick={() => navigate("/")} />
      )}
      <TextButton
        text="quick start."
        onClick={() => navigate("/quick-start")}
      />

      {!isAuthenticated && (
        <TextButton text="sign up." onClick={() => navigate("/sign-up")} />
      )}
      {isAuthenticated && (
        <TextButton
          text="my account."
          onClick={() => navigate("/my-account")}
        />
      )}

      {!isAuthenticated && (
        <TextButton text="login." onClick={() => navigate("/login")} />
      )}
    </Row>
  );
};

const StyledNav = styled.nav<{ theme: Theme }>`
  display: flex;
  justify-content: space-between;
  padding: 1em 3vw;
`;

const StyledLogo = styled.div<{ theme: Theme }>`
  flex: 0 1 33%;
  font-family: "Fira Sans", serif;
  text-decoration: none;
  color: ${(props) => props.theme.colors.dark};
  font-weight: 900;
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
`;

function NavBar() {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();
  const { theme } = useTheme();

  return (
    <StyledNav theme={theme}>
      <StyledLogo theme={theme} onClick={() => navigate("/")}>
        pohtia.
      </StyledLogo>

      <NavBarRight />
      {isAuthenticated && (
        <div
          style={{
            display: "flex",
            flex: "0 1 33%",
            justifyContent: "flex-end",
          }}
        >
          <XpTicker />
        </div>
      )}
    </StyledNav>
  );
}

export default NavBar;
