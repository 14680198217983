import styled from "styled-components";
import { Theme, useTheme } from "../contexts/ThemeContext";
import { bool } from "aws-sdk/clients/signer";

type BtnStyle = "dark" | "light" | "highlight";

type Padding = "s" | "m" | "l";

interface ButtonProps {
  text: string;
  onClick: () => void;
  noShadow?: bool;
  disabled?: boolean;
  color?: "red" | "dark-grey" | "black" | "green";
  type?: "button" | "submit" | "reset";
  btnStyle?: BtnStyle;
  style?: {};
  icon?: string;
  form?: string;
  padding?: Padding;
}

const calcColor = (btnStyle: BtnStyle, theme: Theme): string => {
  switch (btnStyle) {
    case "dark":
    case "highlight":
      return theme.colors.light;
    case "light":
      return theme.colors.dark;
  }
};

const calcBackgroundColor = (btnStyle: BtnStyle, theme: Theme): string => {
  switch (btnStyle) {
    case "dark":
      return theme.colors.dark;
    case "light":
      return theme.colors.light;
    case "highlight":
      return theme.colors.highlight;
  }
};

const calcPadding = (padding: Padding, theme: Theme): string => {
  switch (padding) {
    case "s":
      return (
        theme.spacing.paragraphSpacing.small +
        " " +
        theme.spacing.paragraphSpacing.normal
      );
    case "m":
      return theme.spacing.paragraphSpacing.normal;
    case "l":
      return theme.spacing.paragraphSpacing.large;
  }
};

interface StyleProps {
  theme: Theme;
  btnStyle: BtnStyle;
  noShadow: bool;
  padding: Padding;
}

const StyledButton = styled.button<StyleProps>`
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  padding: ${(props) => calcPadding(props.padding, props.theme)};
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid black;
  color: ${(props) => calcColor(props.btnStyle, props.theme)};
  background-color: ${(props) =>
    calcBackgroundColor(props.btnStyle, props.theme)};
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
    color 0.2s ease-in-out;
  font-weight: ${(props) => props.theme.type.regular};
  box-shadow: ${(props) =>
    props.noShadow ? 0 : "12px 12px 2px 1px rgba(0, 0, 0, 0.2)"};

  &:hover {
    background-color: ${(props) => calcColor(props.btnStyle, props.theme)};
    color: ${(props) => calcBackgroundColor(props.btnStyle, props.theme)};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const StyledImgContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
`;

const StyledImg = styled.img`
  max-height: 1.25em;
`;

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type = "button",
  disabled,
  btnStyle = "dark",
  form,
  noShadow,
  icon,
  padding,
  style,
}) => {
  const { theme } = useTheme();

  return (
    <StyledButton
      theme={theme}
      btnStyle={btnStyle}
      onClick={onClick}
      disabled={disabled}
      type={type}
      form={form}
      noShadow={!!noShadow}
      padding={padding || "m"}
      style={style}
    >
      {icon && (
        <StyledImgContainer>
          <StyledImg src={icon} />
        </StyledImgContainer>
      )}
      {text}
    </StyledButton>
  );
};

export default Button;
