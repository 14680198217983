import { CSSProperties } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
`;

const Column = ({
  children,
  style,
}: {
  children: JSX.Element[] | JSX.Element;
  style?: CSSProperties;
}) => <StyledDiv style={style}>{children}</StyledDiv>;

export default Column;
