import { CSSProperties, useState } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  display: flex;
  flex: 1 1;
  border: none;
  margin-bottom: 1em;
  &:focus {
    outline: none;
  }
`;

interface Props {
  placeHolder?: string;
  onChange?: (state: string) => void;
  style?: CSSProperties;
}

const Input = ({ onChange, placeHolder, style }: Props) => {
  const [text, setText] = useState<string>("");

  return (
    <StyledInput
      style={style}
      placeholder={placeHolder || ""}
      value={text}
      onChange={(e) => {
        const val = e.target.value;
        setText(val);
        onChange && onChange(val);
      }}
    />
  );
};

export default Input;
